html,
body,
#root {
  min-height: 100vh;
  z-index: 0;
  position: relative;
}

.image-gallery {
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.image-gallery-content,
.image-gallery-slide-wrapper,
.image-gallery-thumbnails-wrapper,
.image-gallery-swipe,
.image-gallery-slides {
  height: 100%;
  max-height: 100%;
}

.CarouselRoot,
.CarouselItem,
.CarouselItem > div {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.progress {
  height: 220px;
  width: 50px;
  float: left;
  /* margin: 20px; */
  background-color: transparent;
  border: transparent;
  border-radius: 8px;
}

canvas {
  /* image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor; */
}
